<template>
  <div class="header">
    <topNav></topNav>
    <div v-show="!$route.meta.hideNav" class="headerbox">
      <div class="allcenter">
        <ul class="el-menu-demo">
          <li :class="`portal-nav-firstmenu-li p-pointer ${activeIndex1 == index ? 'active' : ''
                }`" @mouseover="handleMuseover(item)" @mouseout="handleMouseout(item)" v-for="(item, index) in navList"
            :key="index" index="1">
            <a href="#" @click.prevent="gopush(item)">
              {{ item.categoryName }}
            </a>
            <div class="portal-nav-second">
              <ul class="portal-nav-secondmenu">
                <li v-for="(_item, i) in item.children" :key="i" :class="`${activeIndex1 == index && activeIndex2 == i ? 'active' : ''
                }`">
                  <a :href="_item.url" v-if="_item.type == '_blank'" target="_blank" rel="noopener noreferrer">{{
                _item.title }}</a>
                  <a href="#" v-else @click.prevent="gopush(_item)">
                    {{ _item.categoryName }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div :class="`div-box-ul ${childrenS && isShow ? 'isShow' : 'notShow'}`"></div>
    </div>
  </div>
</template>
<script>
import { openLink } from "@/utils/common";
import tree from "@/utils/tree";
import topNav from "./topNav.vue";

export default {
  name: "Header",
  components: { topNav },
  data () {
    return {
      userName: "",
      baseUrl: "https://www.zhejiangmuseum.com",
      navList: [],
      input: "",
      isShow: false,
      childrenS: null,
      activeIndex1: undefined,
      activeIndex2: undefined,
    };
  },
  watch: {
    "$store.state.app.menuList": {
      handler (val) {
        if (val && val.length > 0) {
          this.navList = tree(this.$store.state.app.menuList).filter(
            (v) => v.visible
          );
          this.setActiveRouter();
        }
      },
      deep: true,
    },
    $route: {
      handler (to, from) {
        // const location = window.location;
        // let href = `${
        //   process.env.NODE_ENV == "development" ? this.baseUrl : location.origin
        // }${location.pathname}${location.hash}`;
        this.setActiveRouter();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    gopush (item) {
      openLink(item);
    },
    setActiveRouter () {
      this.activeIndex1 = undefined;
      this.activeIndex2 = undefined;
      this?.navList?.forEach((firstMenu, index) => {
        if (firstMenu.categoryCode == this?.$route?.name || (this.$route.meta.name &&
          firstMenu.categoryCode == this.$route.meta.name) ||
          (this.$route.params.name &&
            firstMenu.categoryCode == this.$route.params.name)) {
          this.activeIndex1 = index;
        }
        firstMenu.children &&
          firstMenu.children.forEach((secondMenu, ind) => {
            if (secondMenu.categoryCode == this?.$route?.name || (this.$route.meta.name &&
              secondMenu.categoryCode == this.$route.meta.name) ||
              (this.$route.params.name &&
                secondMenu.categoryCode == this.$route.params.name)) {
              this.activeIndex1 = index;
              this.activeIndex2 = ind;
            }
          });
      });
    },
    handleMuseover (val) {
      if (val.children && val.children.length > 0) {
        this.childrenS = val.children;
        this.isShow = true;
        this.isHover = true;
      }
    },
    handleMouseout (val) {
      this.childrenS = null;
      this.isShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;

  .allcenter {
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }

  .headerbox {
    width: 100%;
    height: 67px;
    background-color: #faf6f5;

    .el-menu-demo {
      width: 100%;
      height: 67px;
      display: flex;
      justify-content: space-between;

      .portal-nav-firstmenu-li:first-child .portal-nav-second {
        width: 1200px;
        margin-left: 370px;
      }

      .portal-nav-firstmenu-li {
        height: 100%;
        line-height: 67px;
        position: relative;

        >a {
          font-size: 22px;
          color: #525252;
          font-family: syht;
          letter-spacing: 1px;

          &::before {
            position: absolute;
            bottom: 0;
            left: 50%;
            display: none;
            margin-left: -5px;
            z-index: 10;
            transition: all 0.3s ease-in-out;
            content: "";
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-bottom: 5px dashed rgba(143, 130, 130, 0.5);
          }
        }

        .portal-nav-second {
          width: 1200px;
          height: 0;
          position: absolute;
          top: 67px;
          left: 50%;
          display: none;
          transition: height 0.1s ease-out 0s;
          line-height: 51px;
          transform: translateX(-50%);
          z-index: 999999;

          .portal-nav-secondmenu {
            display: flex;
            align-items: center;
            justify-content: center;
            // background: rgba(0, 0, 0, .3);

            li {
              a {
                position: relative;
                padding: 6px 20px;
                font-family: syht-r;
                padding: 6px 15px;
                font-weight: 400;
                font-size: 16px;
                color: #fff;
                line-height: 1.2;
              }

              a::after {
                content: "";
                position: absolute;
                top: 25%;
                right: 0;
                width: 1px;
                height: 50%;
                background-color: hsla(0, 0%, 100%, 0.5);
              }
            }

            li:last-of-type {
              a::after {
                width: 0;
              }
            }
          }
        }
      }

      .portal-nav-firstmenu-li:hover {
        >a {
          color: #7f2a2b;

          &::before {
            display: block;
          }
        }

        .portal-nav-second {
          display: block;

          .portal-nav-secondmenu {
            li:hover {
              >a {
                color: #e22b2d;
              }
            }
          }
        }
      }

      .portal-nav-firstmenu-li.active {
        >a {
          color: #7f2a2b;
        }

        .portal-nav-second {
          .portal-nav-secondmenu {
            li.active {
              >a {
                color: #e22b2d;
              }
            }
          }
        }
      }
    }

    .div-box-ul {
      width: 100%;
      background: rgba(0, 0, 0, 0.3);
      transition: all 0.2s;
      position: relative;
      z-index: 99;

      &.isShow {
        height: 51px;
      }

      &.notShow {
        height: 0;
      }
    }
  }
}
</style>
