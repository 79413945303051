import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.scss"; // global css
import "animate.css";
import "viewerjs/dist/viewer.css";
import pubilcList from "@/views/Collection/pubilcList";
import pubilcDetail from "@/views/Collection/publicDetail";
import VueLazyload from 'vue-lazyload';
import '@/utils/pointsUrl'
import directive from './directive' // directive

Vue.config.productionTip = false;
//在main.js添加下面代码
import './tongji' // 百度统计
//监听元素是否进入视图
Vue.directive("appear-animation", {
  bind(el, binding) {
    const options = binding.value || {};

    let hasEntered = false; // 用于追踪元素是否已经进入视图

    // sjhaihbd-in
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasEntered) {
          // 元素进入视图，添加CSS类触发动画
          el.classList.add(options.appearClass || "slide-in");
          el.style.visibility = "visible";
          hasEntered = true; // 更新状态
        } else if (!entry.isIntersecting && hasEntered) {
          // 元素离开视图，移除CSS类
          if (options.infinite) {
            el.classList.remove(options.appearClass || "slide-in");
            hasEntered = false; // 更新状态
          }
        }
      });
    }, options.intersectionOptions || {});

    observer.observe(el);
  },
});

Vue.use(directive)
Vue.use(ElementUI);
Vue.use(VueLazyload);
/* Vue.use(VueLazyload , {
  preLoad: 1.3,
  loading: require('./assets/loading.png'),
}); */
Vue.component("Swiper", Swiper);
Vue.component("SwiperSlide", SwiperSlide);
Vue.mixin(pubilcList);
Vue.mixin(pubilcDetail);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
