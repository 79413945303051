<template>
  <el-popover placement="bottom" width="240" trigger="click" v-model="visible">
    <div>
      <div class="star-container">
        <div class="txt">
          感谢您的浏览！请您留下宝贵的意见，让我们共同提升博物馆的服务品质。
        </div>
        <div class="rate-container">
          <div class="rate-item">
            <div class="rate-txt">整体设计</div>
            <el-rate
              v-model="form.globeDesignStar"
              :colors="colors"
              @change="handleRateChange"
            >
            </el-rate>
          </div>
          <div class="rate-item">
            <div class="rate-txt">浏览体验</div>
            <el-rate
              v-model="form.browsingExperienceStar"
              :colors="colors"
              @change="handleRateChange"
            >
            </el-rate>
          </div>
          <div class="rate-item">
            <div class="rate-txt">内容质量</div>
            <el-rate
              v-model="form.contentQualityStar"
              :colors="colors"
              @change="handleRateChange"
            >
            </el-rate>
          </div>
          <div class="rate-item">
            <div class="rate-txt">综合评价</div>
            <el-rate
              v-model="form.comprehensiveStar"
              :colors="colors"
              @change="handleRateChange"
            >
            </el-rate>
          </div>
        </div>
        <div class="star-footer">
          <el-checkbox v-model="allChecked" @change="allStar"
            >全五星好评</el-checkbox
          >
          <el-button class="star-btn" :loading="btnLoad" @click="submit">提交评分</el-button>
        </div>
      </div>
    </div>
    <figure slot="reference" class="css-bvybze portal-picture">
      <img class="css-16pe6ju" alt="" src="@/assets/stars.png" />
    </figure>
  </el-popover>
</template>

<script>
import { starEvaluate } from "@/api/system/index.js";
export default {
  name: "WebStar",
  components: {},
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      allChecked: true,
      btnLoad: false,
      form: {
        browsingExperienceStar: 5, //浏览体验
        comprehensiveStar: 5, //综合评价
        contentQualityStar: 5, //内容质量
        globeDesignStar: 5, //整体设计
        type: 1,
      },

      colors: ["#7C3C37", "#7C3C37", "#7C3C37"],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleRateChange() {
      this.allChecked = false;
      if (
        this.form.browsingExperienceStar == 5 &&
        this.form.comprehensiveStar == 5 &&
        this.form.contentQualityStar == 5 &&
        this.form.globeDesignStar == 5
      ) {
        this.allChecked = true;
      }
    },
    allStar(val) {
      if (val) {
        this.form.browsingExperienceStar = 5;
        this.form.comprehensiveStar = 5;
        this.form.contentQualityStar = 5;
        this.form.globeDesignStar = 5;
      }
    },
    submit() {
      this.btnLoad = true;
      starEvaluate({ ...this.form }).then((res) => {
        if (res.code == 0) {
          this.$message.success("评价成功");
          this.visible = false;
        }
      }).finally(() => {
        this.allChecked = true;
        this.allStar(true);
        this.btnLoad = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.star-container {
  .txt {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #151515;
    line-height: 21px;
    text-align: left;
  }

  .star-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 12px;
    border-top: 1px solid #f1f1f1;
    height: 60px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #646464;

    .star-btn {
      width: 81px;
      height: 32px;
      background-color: #7f2a2b;
      color: #fff;
      padding: 5px 8px;
      border-radius: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.rate-container {
  .rate-item {
    display: flex;
    height: 24px;
    align-items: center;
    margin-top: 7px;

    .rate-txt {
      padding-right: 6px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #646464;
      line-height: 26px;
    }
  }
}

:deep(.el-rate) {
  height: 24px;
}

:deep(.el-rate__icon) {
  font-size: 23px;
  margin-right: 4px;
}

:deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
  border-color: #7f2a2b;
}

:deep(
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner
  ) {
  background-color: #7f2a2b;
}

:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #7f2a2b;
}

.portal-picture {
  height: 100px;
  cursor: pointer;
  img {
    width: 100%;
    object-fit: cover;
    display: inline-block;
    border: 0;
  }

  .css-16pe6ju {
    height: 100%;
    max-width: 100%;
  }
}

.css-bvybze {
  border-radius: 2px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(247, 247, 247);
  overflow: hidden;
  margin: 0;
}
</style>