import Vue from 'vue'
import VueRouter from 'vue-router'
import { siteDetail, categoryList } from '@/api/system'
import store from '@/store'
import { getAccessToken } from '@/utils/auth'
import { JudgeLoginDate } from '@/utils/pointsUrl'
Vue.use(VueRouter)

import Home from '@/views/Home/index.vue'

const routes = [
  {
    path: '/',
    // redirect: '/Home',
    name: 'index',
    meta: {
      dispalyMargin: true
    },
    component: (resolve) => require(['@/views/MuseumShop/index.vue'], resolve),
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Culture_product_detail',
    name: 'Culture_product_detail',
    component: (resolve) => require(['@/views/Home/detail.vue'], resolve),
  },
  // 浙博概况首页
  {
    path: '/Survey',
    name: 'Survey',
    meta: {
      dispalyMargin: true,
    },
    component: (resolve) => require(['@/views/Survey/index.vue'], resolve),
  },
  //浙博简介
  {
    path: '/Survey/Introduction',
    name: 'Introduction',
    component: (resolve) =>
      require(['@/views/Survey/introduction.vue'], resolve),
  },
  //历史沿革
  {
    path: '/Survey/History',
    name: 'History',
    component: (resolve) => require(['@/views/Survey/history.vue'], resolve),
  },
  //机构设置
  {
    path: '/Survey/Organization',
    name: 'Organization',
    component: (resolve) =>
      require(['@/views/Survey/organization.vue'], resolve),
  },
  //历任馆长
  {
    path: '/Survey/Leader',
    name: 'Leader',
    component: (resolve) => require(['@/views/Survey/leader.vue'], resolve),
  },
  //制度规范
  {
    path: '/Survey/Rules',
    name: 'Rules',
    component: (resolve) => require(['@/views/Survey/rules.vue'], resolve),
  },
  //详情页
  {
    path: '/Survey/Rules/:name/:id',
    name: 'RulesDetail',
    component: (resolve) =>
      require(['@/views/Survey/rulesDetail.vue'], resolve),
  },
  //交流合作
  {
    path: '/Survey/InterExchange',
    name: 'InterExchange',
    component: (resolve) =>
      require(['@/views/Survey/interExchange.vue'], resolve),
  },
  //年度事记
  {
    path: '/Survey/Events',
    name: 'Events',
    component: (resolve) => require(['@/views/Survey/events.vue'], resolve),
  },
  //历年总结
  {
    path: '/Survey/Summary',
    name: 'Summary',
    component: (resolve) => require(['@/views/Survey/summary.vue'], resolve),
  },
  //教育基地与共建单位
  {
    path: '/Survey/BaseAndUnit',
    name: 'BaseAndUnit',
    component: (resolve) =>
      require(['@/views/Survey/baseAndUnit.vue'], resolve),
  },
  //展览首页
  {
    path: '/Exhibition',
    name: 'Exhibition',
    component: (resolve) => require(['@/views/Exhibition/index.vue'], resolve),
  },
  //基本陈列
  {
    path: '/Exhibition/BaseExhibition',
    name: 'BaseExhibition',
    component: (resolve) =>
      require(['@/views/Exhibition/baseExhibition.vue'], resolve),
  },
  //展览详情
  {
    path: '/Exhibition/BaseExhibition/:id',
    name: 'baseExhibitionDetail',
    meta: {
      name: 'BaseExhibition',
    },
    component: (resolve) =>
      require(['@/views/Exhibition/baseExhibitionDetail.vue'], resolve),
  },
    //展览详情
    {
      path: '/Exhibition/BaseExhibition/:id/:tagId',
      name: 'baseExhibitionDetail',
      meta: {
        name: 'BaseExhibition',
      },
      component: (resolve) =>
         require(['@/views/Exhibition/tagsDeatil.vue'], resolve),
        
    },
  //展览关键词
  {
    path: '/Exhibition/BaseExhibition/:id?label=:label',
    name: 'baseExhibitionIntroduce',
    meta: {
      name: 'BaseExhibition',
    },
    component: (resolve) =>
      require(['@/views/Exhibition/baseExhibitionIntroduce.vue'], resolve),
  },
  //临时展览
  {
    path: '/Exhibition/TemporaryExhibition',
    name: 'TemporaryExhibition',
    component: (resolve) =>
      require(['@/views/Exhibition/temporaryExhibition.vue'], resolve),
  },
  //临时展览详情
  {
    path: '/Exhibition/TemporaryExhibition/:id',
    name: 'temporaryDetail',
    meta: {
      name: 'TemporaryExhibition',
    },
    component: (resolve) =>
      require(['@/views/Exhibition/temporaryDetail.vue'], resolve),
  },
  //临时展览标签详情
  {
    path: '/Exhibition/TemporaryExhibition/:id/:tagId',
    name: 'labelDetail',
    meta: {
      name: 'TemporaryExhibition',
    },
    component: (resolve) =>
      require(['@/views/Exhibition/tagsDeatil.vue'], resolve),
  },
  //资讯页-首页
  {
    path: '/News',
    name: 'News',
    component: (resolve) => require(['@/views/News/index.vue'], resolve),
  },
  //资讯页-通知公告
  {
    path: '/News/Notice',
    name: 'Notice',
    component: (resolve) => require(['@/views/News/notice.vue'], resolve),
  },
  //资讯页-新闻动态
  {
    path: '/News/Snews',
    name: 'Snews',
    component: (resolve) => require(['@/views/News/Snews/index.vue'], resolve),
  },
  //资讯页-新闻动态-展览资讯
  {
    path: '/News/Snews/OpenExhibition',
    name: 'OpenExhibition',
    component: (resolve) =>
      require(['@/views/News/Snews/openExhibition.vue'], resolve),
  },
  //资讯页-新闻动态-新闻快讯
  {
    path: '/News/Snews/NewsInfo',
    name: 'NewsInfo',
    component: (resolve) =>
      require(['@/views/News/Snews/newsInfo.vue'], resolve),
  },
  //资讯页-新闻动态-社会教育
  {
    path: '/News/Snews/ActNews',
    name: 'ActNews',
    component: (resolve) =>
      require(['@/views/News/Snews/actNews.vue'], resolve),
  },
  //资讯页-新闻动态-文博讲坛
  {
    path: '/News/Snews/ForumNews',
    name: 'ForumNews',
    component: (resolve) =>
      require(['@/views/News/Snews/forumNews.vue'], resolve),
  },
  //资讯页-新闻动态-志愿者
  {
    path: '/News/Snews/VolNews',
    name: 'VolNews',
    component: (resolve) =>
      require(['@/views/News/Snews/volNews.vue'], resolve),
  },
  //展览回顾
  {
    path: '/Exhibition/ExhibitiomReview',
    name: 'ExhibitiomReview',
    component: (resolve) =>
      require(['@/views/Exhibition/exhibitiomReview.vue'], resolve),
  },
  //展览回顾详情
  {
    path: '/Exhibition/ExhibitiomReview/:id',
    name: 'exhibitiomDetail',
    meta: {
      name: 'ExhibitiomReview',
    },
    component: (resolve) =>
      require(['@/views/Exhibition/exhibitiomDetail.vue'], resolve),
  },
  //展览回顾标签详情
  {
    path: '/Exhibition/ExhibitiomReview/:id/:tagId',
    name: 'labelDetail',
    meta: {
      name: 'ExhibitiomReview',
    },
    component: (resolve) =>
      require(['@/views/Exhibition/tagsDeatil.vue'], resolve),
  },
  //云课堂
  {
    path: '/Education/CloudClassroom',
    name: 'CloudClassroom',
    component: (resolve) =>
      require(['@/views/Education/cloudClassroom.vue'], resolve),
  },
  //云课堂掌上浙博详情
  {
    path: '/Education/CloudClassroom/:code/:id',
    name: 'CloudClassroomDetail',
    meta: {
      name: 'CloudClassroom',
    },
    component: (resolve) =>
      require(['@/views/Education/CloudClassroomDetail.vue'], resolve),
  },
  //云课堂红色文物故事详情
  {
    path: '/Education/redDetail/:code/:id',
    name: 'redDetail',
    meta: {
      name: 'CloudClassroom',
    },
    component: (resolve) =>
      require(['@/views/Education/redDetail.vue'], resolve),
  },
  //学术首页
  {
    path: '/Research',
    name: 'Research',
    component: (resolve) => require(['@/views/Research/index.vue'], resolve),
  },
  //专业部门
  {
    path: '/Research/ResDepartment',
    name: 'ResDepartment',
    component: (resolve) =>
      require(['@/views/Research/ResDepartment.vue'], resolve),
  },
  //科研机构
  {
    path: '/Research/ResService',
    name: 'ResService',
    component: (resolve) =>
      require(['@/views/Research/ResService.vue'], resolve),
  },
  //学术研讨
  {
    path: '/Research/ForeignExchange',
    name: 'ForeignExchange',
    component: (resolve) =>
      require(['@/views/Research/ForeignExchange.vue'], resolve),
  },
  //课题研究
  {
    path: '/Research/ProjectResearch',
    name: 'ProjectResearch',
    component: (resolve) =>
      require(['@/views/Research/ProjectResearch.vue'], resolve),
  },
  //课题研究详情
  {
    path: '/Research/ProjectResearch/:id',
    name: 'ProjectResearchDetail',
    meta: {
      name: 'ProjectResearch',
    },
    component: (resolve) => require(['@/views/Research/detail.vue'], resolve),
  },
  //专业人员
  {
    path: '/Research/Professional',
    name: 'Professional',
    component: (resolve) =>
      require(['@/views/Research/Professional.vue'], resolve),
  },
  //专业人员-历任专家
  {
    path: '/Research/Professional?third=PreExperts',
    name: 'PreExperts',
    component: (resolve) =>
      require(['@/views/Research/Professional.vue'], resolve),
  },
  //专业人员-任职人员
  {
    path: '/Research/Professional?third=InservicePro',
    name: 'InservicePro',
    component: (resolve) =>
      require(['@/views/Research/Professional.vue'], resolve),
  },
  //专业人员-优秀人才
  {
    path: '/Research/Professional?third=TalentedPersons',
    name: 'TalentedPersons',
    component: (resolve) =>
      require(['@/views/Research/Professional.vue'], resolve),
  },
  //专业人员详情
  {
    path: '/Research/Professional/:id',
    name: 'ProfessionalDeatil',
    meta: {
      name: 'Professional',
    },
    component: (resolve) =>
      require(['@/views/Research/ProfessionalDeatil.vue'], resolve),
  },
  //论文论著
  {
    path: '/Research/SpecialThesis',
    name: 'SpecialThesis',
    component: (resolve) =>
      require(['@/views/Research/SpecialThesis.vue'], resolve),
  },
  //论文论著详情
  {
    path: '/Research/SpecialThesis/:id',
    name: 'SpecialThesisDetail',
    meta: {
      name: 'SpecialThesis',
    },
    component: (resolve) =>
      require(['@/views/Research/SpecialThesisDetail.vue'], resolve),
  },
  //出版图书
  {
    path: '/Research/CulPublication',
    name: 'CulPublication',
    component: (resolve) =>
      require(['@/views/Research/CulPublication.vue'], resolve),
  },
  //数字浙博首页
  {
    path: '/DigitalMuseum',
    name: 'DigitalMuseum',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/index.vue'], resolve),
  },
  //数字展厅
  {
    path: '/DigitalMuseum/DigitalCommonExhibition',
    name: 'DigitalCommonExhibition',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/DigitalCommonExhibition.vue'], resolve),
  },
  {
    path: '/DigitalMuseum/DigitalCommonExhibition/DigitalExhibition',
    name: 'DigitalExhibition',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/DigitalCommonExhibition.vue'], resolve),
  },
  //专题展览
  // {
  //   path: '/DigitalMuseum/SpecialExhibition',
  //   name: 'SpecialExhibition',
  //   component: (resolve) =>
  //     require(['@/views/DigitalMuseum/SpecialExhibition.vue'], resolve),
  // },
  {
    path: '/DigitalMuseum/DigitalCommonExhibition/FeaturesExh',
    name: 'FeaturesExh',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/SpecialExhibition.vue'], resolve),
  },
  //3D文物
  {
    path: '/DigitalMuseum/DigitalCollection',
    name: 'DigitalCollection',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/DigitalCollection.vue'], resolve),
  },
  // //古籍图书
  // {
  //   path: '/DigitalMuseum/AncientBooks',
  //   name: 'AncientBooks',
  //   component: (resolve) =>
  //     require(['@/views/DigitalMuseum/AncientBooks.vue'], resolve),
  // },
  //琴音赏析
  {
    path: '/DigitalMuseum/Music',
    name: 'Music',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/Music.vue'], resolve),
  },
  //数字展览
  {
    path: '/DigitalMuseum',
    name: 'DigitalMuseum',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/index.vue'], resolve),
  },
  //影像浙博
  {
    path: '/DigitalMuseum/Video',
    name: 'Video',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/Video/index.vue'], resolve),
  },
  //影像浙博-展览活动
  {
    path: '/DigitalMuseum/Video/ExhVideo',
    name: 'ExhVideo',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/Video/exhVideo.vue'], resolve),
  },
  //影像浙博-媒体宣传
  {
    path: '/DigitalMuseum/Video/SocialAttention',
    name: 'SocialAttention',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/Video/socialAttention.vue'], resolve),
  },
  //影像浙博-云讲国宝
  {
    path: '/DigitalMuseum/Video/CloudTreasure',
    name: 'CloudTreasure',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/Video/cloudTreasure.vue'], resolve),
  },
  //影像浙博-红色追忆
  {
    path: '/DigitalMuseum/Video/revolution',
    name: 'revolution',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/Video/revolution.vue'], resolve),
  },
  //影像浙博-古代小知识
  {
    path: '/DigitalMuseum/Video/VideoTextbooks',
    name: 'VideoTextbooks',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/Video/videoTextbooks.vue'], resolve),
  },
  //影像浙博-志愿者之歌
  {
    path: '/DigitalMuseum/Video/VolSongs',
    name: 'VolSongs',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/Video/volSongs.vue'], resolve),
  },
  //影像浙博=详情
  {
    path: '/DigitalMuseum/Video/:name/:id',
    name: 'videoDetail',
    component: (resolve) =>
      require(['@/views/DigitalMuseum/Video/videoDetail.vue'], resolve),
  },
  //东方博物首页
  {
    path: '/Dfbw',
    name: 'Dfbw',
    component: (resolve) => require(['@/views/Dfbw/index.vue'], resolve),
  },
  //东方博物详情页面
  {
    path: '/Dfbw/:id',
    name: 'dfbwDetail',
    meta: {
      name: 'Dfbw',
    },
    component: (resolve) => require(['@/views/Dfbw/dfbwDetail.vue'], resolve),
  },
  //东方博物列表详情页
  {
    path: '/Dfbw/:code/:id',
    name: 'listDetail',
    meta: {
      name: 'Dfbw',
    },
    component: (resolve) => require(['@/views/Dfbw/listDetail.vue'], resolve),
  },
  // 典藏首页
  {
    path: '/Collection',
    name: 'Collection',
    component: (resolve) => require(['@/views/Collection/index.vue'], resolve),
  },
  // 典藏-镇馆之宝
  {
    path: '/Collection/Treasure',
    name: 'Treasure',
    component: (resolve) =>
      require(['@/views/Collection/Treasure/index.vue'], resolve),
  },
  // 典藏-馆藏精品
  {
    path: '/Collection/ExcellentCollection',
    name: 'ExcellentCollection',
    component: (resolve) =>
      require(['@/views/Collection/ExcellentCollection/index.vue'], resolve),
  },
  // 典藏-文物详情
  {
    path: '/Collection/ExcellentCollection/:id',
    // name: "CollectionExcellentCollection",
    meta: {
      name: 'ExcellentCollection',
    },
    component: (resolve) =>
      require(['@/views/Collection/ExcellentCollection/detail.vue'], resolve),
  },
  // 典藏-古籍图书
  {
    path: '/Collection/AncientBooks',
    name: 'AncientBooks',
    component: (resolve) =>
      require(['@/views/Collection/AncientBooks/index.vue'], resolve),
  },
  // 典藏-古籍图书-详情
  {
    path: '/Collection/AncientBooks/:id',
    // name: "CollectionAncientBooks",
    meta: {
      name: 'AncientBooks',
    },
    component: (resolve) =>
      require(['@/views/Collection/AncientBooks/detail.vue'], resolve),
  },
  // 典藏-文物捐赠
  {
    path: '/Collection/CulDonation',
    name: 'CulDonation',
    component: (resolve) =>
      require(['@/views/Collection/CulDonation/index.vue'], resolve),
  },
  //参观页-参观指南
  {
    path: '/Service',
    name: 'Service',
    component: (resolve) => require(['@/views/Service/index.vue'], resolve),
  },
  //参观页-参观指南
  {
    path: '/Service/TourGuide',
    name: 'TourGuide',
    component: (resolve) => require(['@/views/Service/tourGuide.vue'], resolve),
  },
  //参观页-讲解服务
  {
    path: '/Service/AppointExplain',
    name: 'AppointExplain',
    component: (resolve) =>
      require(['@/views/Service/AppointExplain/index.vue'], resolve),
  },
  //参观页-讲解服务-岗位职责
  {
    path: '/Service/AppointExplain/Responsibilities',
    name: 'Responsibilities',
    component: (resolve) =>
      require(['@/views/Service/AppointExplain/responsibilities.vue'], resolve),
  },
  //参观页-志愿服务
  {
    path: '/Service/Volunteer',
    name: 'Volunteer',
    component: (resolve) =>
      require(['@/views/Service/Volunteer/index.vue'], resolve),
  },
  //参观页-志愿服务-志愿者名片
  {
    path: '/Service/Volunteer/VolHome/VolCard',
    name: 'VolCard',
    component: (resolve) =>
      require(['@/views/Service/Volunteer/volCard.vue'], resolve),
  },
  //参观页-志愿服务-风采展示
  {
    path: '/Service/Volunteer/VolAction',
    name: 'VolAction',
    component: (resolve) =>
      require(['@/views/Service/Volunteer/volAction.vue'], resolve),
  },
  //参观页-失物招领
  {
    path: '/Service/LostFund',
    name: 'LostAndFound',
    component: (resolve) =>
      require(['@/views/Service/LostFund/index.vue'], resolve),
  },
  //FriendLink
  {
    path: '/FriendLink',
    name: 'FriendLink',
    component: (resolve) => require(['@/views/FriendLink/index.vue'], resolve),
  },
  // 全局搜索
  {
    path: '/Search',
    name: 'Search',
    meta: {
      hideNav: true,
    },
    component: (resolve) => require(['@/views/Search/index.vue'], resolve),
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0,
    }
  },
})

router.beforeEach((to, from, next) => {
  const app = document.getElementById('app')
  app.scrollTop = 0
  if (
    getAccessToken() &&
    store?.state?.user?.memberInfo &&
    Object.keys(store?.state?.user?.memberInfo).length === 0
  ) {
    store.dispatch('GetMemberInfo')
  }
  if (store?.state?.app?.menuList && store?.state?.app?.menuList?.length > 0) {
    next()
  } else {
    siteDetail({ siteCode: 'zhebo_cn' })
      .then((res) => {
        if (res.success) {
          store.commit('app/setSiteData', res.data)
          categoryList({ siteId: res.data[0].id })
            .then((ret) => {
              if (res.success) {
                store.commit('app/setCategoryList', ret.data)
                store.commit('app/setMenuList', ret.data)
                next()
              }
            })
            .catch((err) => {
              console.log(err)
              next()
            })
        }
      })
      .catch((err) => {
        console.log(err)
        next()
      })
  }
})

router.afterEach((to, from) => {
  JudgeLoginDate()
});
export default router
