import request from '@/utils/request'

// 登录方法
export function login(username, password, captchaVerification, socialType, socialCode, socialState) {
  const data = {
    username,
    password,
    captchaVerification,
    // 社交相关
    socialType,
    socialCode,
    socialState
  }
  return request({
    url: '/app/auth/login',
    method: 'post',
    data: data
  })
}

// 微信扫码登录
export function socialLoginWechat(data) {
  return request({
    url:'/app/auth/login',
    method:'post',
    data
  })
}

// 手机号登录
export function mobileLogin(data) {
  return request({
    url:'/app/auth/mobileLogin',
    method:'post',
    data
  })
}

// 微信用户退出登录
export function logOutWechat(params) {
  return request({
    url:'/app/auth/logout',
    method:'get',
    params
  })
}

// 获取微信登录用户信息
export function getMemberInfo(params) {
  return request({
    url: '/app/auth/getMemberInfo',
    method: 'get',
    params
  })
}

// 更新会员信息
export function setMemberInfo(data) {
  return request({
    url: '/app/auth/update',
    method: 'post',
    data
  })
}

// 获取用户详细信息
export function getInfo(params) {
  return request({
    url: '/app/auth/get-permission-info-byappuniqueid',
    method: 'get',
    params
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/app/auth/logout',
    method: 'post'
  })
}


/* 统一登录相关 */

export function thirdLogin(token) {
  return request({
    url: '/app/auth/third/login',
    method: 'post',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    params: {
      token: token
    }
  })
}