import { integralRuleRecord } from "@/api/system/index.js";
import store from '@/store/index'
import { getAccessToken } from "./auth";
import dayjs from 'dayjs';
const urlPoints = [
  // 用户收藏官网栏目
  {
    url: "/web-fav/click",
    code: "R003",
  },
  // 用户官网提交评分
  {
    url: "/web-star/create",
    code: "R007",
  },
];

// 创建原始的XMLHttpRequest对象
let oldOpen = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function () {
  return oldOpen.apply(this, arguments);
};
let oldSend = XMLHttpRequest.prototype.send;
XMLHttpRequest.prototype.send = function () {
  this.addEventListener(
    "readystatechange",
    function () {
      // 拦截response
      if (this.readyState === 4) {
        try {
          // console.log(this)
          if(this.status == 200) {
            dealPoint(this)
          }
        } catch (err) {
          // console.log("错误", err);
        }
      }
    },
    false
  );
  return oldSend.apply(this, arguments);
};

//接口埋点方法
async function dealPoint(res) {
  let response = JSON.parse(res.response);
  if(response.code != 0 || !getAccessToken()) {
    return;
  }
  let fItem = urlPoints.find(item => res.responseURL.includes(item.url));
  if(fItem) {
    let userId = store.state.user.memberInfo.id ? store.state.user.memberInfo.id : JSON.parse(sessionStorage.getItem('memberInfo')).id;
    if(!userId) {
      const userRes = await store.dispatch('GetMemberInfo')
      userId = userRes.data.id;
    }
    let params = {
      actionCode: fItem.code,
      fieldMap:{},
      memberType:1,
      memberIdOrPhone: userId,
      originType:4,
      requestDomain:2
    }
    integralRuleRecord(params)
  }
}

//判断登录日期
export async function JudgeLoginDate() {
  if(!getAccessToken()) {
    return
  }
  let loginDate = localStorage.getItem('lgDate');
  let now = new Date();
  let nowDate = dayjs().format('YYYY-MM-DD');
  if(!loginDate || loginDate !== nowDate) {
    //首次登录系统 || 今日第一次登录
    let userId = store.state.user.memberInfo?.id ? store.state.user.memberInfo.id : JSON.parse(sessionStorage.getItem('memberInfo'))?.id;
    if(!userId) {
      const userRes = await store.dispatch('GetMemberInfo')
      userId = userRes.data.id;
    }
    let params = {
      actionCode: 'R001',
      fieldMap:{},
      memberType:1,
      memberIdOrPhone: userId,
      originType:4,
      requestDomain:2
    }
    integralRuleRecord(params).then(res => {
      if(res.code == 0) {
        localStorage.setItem('lgDate',nowDate)
      }
    })
  }
}
