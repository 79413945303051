import { getPageDeatil } from "@/utils/common";
const pubilcDetail = {
  data() {
    return {
      detailData: {},
      detailLoading: true,
    }
  },

  methods: {
    getPublicDetail() {
      let params = {
        // this.$route.params.name,
        categoryCode: this.categoryCode,
        siteId: this.$store.state.app.siteData[0].id,
        contentId: this.$route.params.id,
      }
      getPageDeatil(params).then((res) => {
        if (res.success) {
          //console.log('详情数据', res)
          this.detailData = res.data?.[0] ?? {};
        }
      });
      this.detailLoading = false;
    },
  }
}

export default pubilcDetail