// 项目配置项

const config = {
  // 地址
  baseUrl: 'http://192.168.1.205:18080',
  // 全局提示配置
  message: {
    // 显示接口请求失败提示
    showErrorMessage: true,
    // 最大显示数
    maxCount: 3,
  },
}


// loadConfigs()

export default config
