
import { siteDetail, categoryList } from '@/api/system'
import tree from "@/utils/tree";

const app = {
  namespaced: true,
  state: {
    siteData: [],//站点信息
    categoryList: [],//栏目列表
    menuList: [], //菜单树
  },
  mutations: {
    setSiteData(state, data) {
      state.siteData = data
    },
    setCategoryList(state, data) {
      state.categoryList = data
    },
    setMenuList(state, data) {
      const menuTree = tree(data)?.fromList({ pid: "parent_id" }) ?? [];
      // console.log(menuTree, 'menuTree');
      state.menuList = menuTree
    },
  },
  actions: {
    async getSiteData({ commit }) {
      const res = await siteDetail({ siteCode: 'zhebo_cn' })
      if (res.success) {
        commit('setSiteData', res.data)
        const ret = await categoryList({ siteId: res.data[0].id })
        if (res.success) {
          commit('setCategoryList', ret.data)
          commit('setMenuList', ret.data)
        }
      }
    },
  },
}

export default app
