import { getMemberInfo, logOutWechat } from "@/api/system/login";
import { integralRuleRecord } from "@/api/system";
import { removeToken } from "@/utils/auth";

const user = {
  state: {
    userInfo: {},
    memberInfo: {},
  },

  mutations: {
    SET_MEMBERINFO: (state, memberInfo) => {
      sessionStorage.setItem("memberInfo", JSON.stringify(memberInfo));
      state.memberInfo = memberInfo;
    },
  },

  actions: {
    // 获取会员信息
    GetMemberInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getMemberInfo()
          .then((res) => {
            if (res.code == 0) {
              commit("SET_MEMBERINFO", res.data);
              resolve(res);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 会员退出
    LogOutWechat({ commit }) {
      return new Promise((resolve, reject) => {
        logOutWechat()
          .then(() => {
            commit("SET_MEMBERINFO", {});
            removeToken();
            resolve();
          })
          .catch((error) => {
            removeToken();
            reject(error);
          });
      });
    },

    IntegralRuleRecord({commit, state}, fieldMap) {
      let data = {
        "actionCode": "R002",
        "fieldMap": {
          "field1": "",
          "field2": "",
          "field3": "",
          "field4": "",
          "field5": "",
          "field6": "",
          "field7": "",
          "field8": "",
          "field9": "",
          "field10": "",
        },
        "memberIdOrPhone": state.memberInfo.id,
        "memberType": 1,
        "originType": 4,
        "requestDomain": 2
      }
      data.fieldMap = Object.assign(data.fieldMap ,fieldMap);
      return new Promise ((resolve, reject)=>{
        integralRuleRecord(data).then(response => {
          
        }).catch(err => {
          console.log(err)
        })
      });
    }
  },
};

export default user;
