<template>
  <div class="quickmenubox">
    <div class="allcenter">
      <div class="logo-container">
        <a href="/">
          <div class="zblogo"></div>
        </a>
        <a href="/" rel="noreferrer">
          <div class="gmslogo"></div>
        </a>
      </div>
      <div class="quickmenu">
        <div class="language p-pointer">
          <span class="el-dropdown-link">
            <i class="iconfont iconzh"></i>
            中/英
          </span>
          <ul class="down">
            <li>
              <a @click="handleToLink('zhebo_cn')">中文</a>
              <!-- href="/" -->
            </li>
            <li>
              <a @click="handleToLink('zhebo_en')" target="_blank" rel="noreferrer">
                English
              </a>
              <!-- href="https://www.zhejiangmuseum.com/_en/" -->
            </li>
          </ul>
        </div>
        <div class="input-with-select">
          <el-input type="text" placeholder="请输入关键词..." v-model="input" class="" @keyup.enter.native="goSearch">
            <el-button slot="append" icon="el-icon-search" @click="goSearch()"></el-button>
          </el-input>
        </div>
      </div>
      <div id="assist-open" style="cursor:pointer;">无障碍</div>
      <div class="right-menu">
        <el-dropdown v-if="memberInfo.id" class="avatar-container hover-effect" trigger="click">
          <div class="avatar-wrapper">
            <span v-if="memberInfo.nickName" class="user-nickname">{{ memberInfo.nickName }}</span>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="linkToInfoCenter">
              <span>用户中心</span>
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span v-else @click="login" class="login-btn">登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import { siteDetail } from "@/api/system";
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      input: '',
    }
  },
  computed: {
    memberInfo () {
      return this.$store.state.user.memberInfo;
    }
  },
  watch: {},
  created () { },
  mounted () { },
  methods: {
    goSearch() {
      // this.$router.push({ path: 'search', query: { keyword: this.input } })
      if (this.$route.name !== 'Search') {
        window.open(`#/Search?keyword=${this.input}`, '_blank');
      }
    },
    login () {
      window.location.href = `${window.location.origin}/user/#/login?redirect=${encodeURIComponent(window.location.href)}`
    },
    async logout () {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "del-model",
        center: true
      })
        .then(() => {
          this.$store.dispatch('LogOutWechat').then(() => {
            window.sensors_sw.logout();
            location.reload()
          })
        })
        .catch(() => { })
    },
    linkToInfoCenter () {
      window.location.href = `${window.location.origin}/user/#/personalCenter`
    },
    handleToLink (code) {
      siteDetail({ siteCode: code }).then((res) => {
        if (res.success) {
          const link = res?.data[0]?.website
          let url = window.location.origin + link
          window.open(url, '_blank');
        }
      })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.allcenter {
  width: 1200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.quickmenubox {
  width: 100%;
  height: 95px;

  .logo-container {
    width: 560px;
    height: 57px;
    // margin-left: -50px;
    display: flex;
    justify-content: space-between;

    .zblogo {
      width: 240px;
      height: 54px;
      background: url("~@/assets/header-logo.png") no-repeat;
      background-size: 100% 100%;
    }

    .gmslogo {
      width: 295px;
      height: 37px;
      margin-top: 12px;
      background: url("~@/assets/header-logo-title.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .quickmenu {
    display: flex;
    align-items: center;

    .language {
      margin-right: 4px;
      margin-left: 27px;
      font-size: 14px;

      .el-dropdown-link {
        display: flex;
        align-items: center;

        .iconfont {
          margin-right: 7px;
          font-family: "iconfont" !important;
          font-size: 17px;
          font-style: normal;
        }

        .iconzh::before {
          vertical-align: sub;
          content: url('~@/assets/icons/svg/iconzh.svg');
        }
      }

      .down {
        position: absolute;
        top: 60px;
        display: none;
        padding: 5px 12px;
        background: #bababa;
        border-radius: 5px;
        transition: all .3s;

        li {
          color: #fff;
          line-height: 24px;
        }
      }
    }

    .language:hover .down {
      display: block;
    }

    .input-with-select {
      width: 259px;
      height: 30px;
      margin-left: 27px;
      border: 1px solid #474747;
      border-radius: 24px;
      position: relative;

      ::v-deep .el-input {
        position: absolute;
        top: -1px;

        .el-input__inner {
          height: 30px;
          background-color: transparent;
          border: 1px solid transparent;
          border-radius: 0;
        }

        .el-input-group__append {
          padding: 0 20px 0 0;
          background-color: transparent;
          border: 1px solid transparent;
          border-radius: 24px;
        }
      }
    }
  }
}

.right-menu {
  height: 100%;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  .right-menu-item {
    padding: 0 8px;
    height: 100%;
    font-size: 18px;
    color: #5a5e66;
    vertical-align: text-bottom;

    &.hover-effect {
      cursor: pointer;
      transition: background .3s;

      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }
  }

  .avatar-container {
    height: 30px;
    line-height: 30px;
    margin-right: 30px;
    color: #000;

    .avatar-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .user-avatar {
        cursor: pointer;
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }

      .user-nickname {
        margin-left: 5px;
        font-size: 14px;
      }

      .el-icon-caret-bottom {
        cursor: pointer;
        position: absolute;
        right: -20px;
        // top: 25px;
        font-size: 12px;
      }
    }
  }

  .login-btn {
    cursor: pointer;

    &:hover {
      color: #409EFF;
    }
  }
}

#assist-open {
  cursor: pointer;

  &:hover {
    color: #409EFF;
  }
}
</style>
