
import request from './request'


// 获取站点信息
export function siteDetail(data) {
  return request({
    url: '/api/site/detail',
    method: 'POST',
    data,
  })
}
// 获取栏目列表接口
export function categoryList(data) {
  return request({
    url: '/api/category/list',
    method: 'POST',
    data,
  })
}
// 获取栏目下内容列表接口
export function contentList(data) {
  return request({
    url: '/api/content/page',
    method: 'POST',
    data,
  })
}
// 获取栏目下内容列表接口
export function contentNew(data) {
  return request({
    url: '/api/content/news',
    method: 'POST',
    data,
  })
}
//  获取栏目下内容详情接口
export function contentDetail(data) {
  return request({
    url: '/api/content/detail',
    method: 'POST',
    data,
  })
}
//  收藏或取消收藏
export function favClick(data) {
  return request({
    url: '/app/web-fav/click',
    method: 'POST',
    data,
  })
}
//  是否已被收藏
export function isFav(data) {
  return request({
    url: '/app/web-fav/isFav',
    method: 'POST',
    data,
  })
}
// 获取展览下内容分页接口
export function exhibitionList(data) {
  return request({
    url: '/api/exhibition/page',
    method: 'POST',
    data,
  })
}
// 获取展览下详情接口
export function exhibitionDetail(data) {
  return request({
    url: '/api/exhibition/detail',
    method: 'POST',
    data,
  })
}

// 获取字典列表接口
export function dictList(data) {
  return request({
    url: '/api/dict/list',
    method: 'POST',
    data,
  })
}
// 获取典藏精品下内容分页接口
export function exhibitPage(data) {
  return request({
    url: '/api/exhibit/page',
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data,
  })
}
//  获取展品详情接口
export function exhibitDetail(data) {
  return request({
    url: '/api/exhibit/detail',
    method: 'POST',
    data,
  })
}
//  获取父栏目下文章聚合分页接口
export function contentListByParentCode(data) {
  return request({
    url: '/api/content/listByParentCode',
    method: 'POST',
    data,
  })
}
// 获取活动接口
export function activityPage(params) {
  return request({
    url: '/app/activity/page',
    method: 'GET',
    params,
  })
}

// 接口获取过滤推荐的数据
export function pageByRecommend(data) {
  return request({
    url: '/api/content/pageByRecommend',
    method: 'POST',
    data,
  })
}

// 获取栏目下内容-过滤字段分页接口
export function pageByDict(data) {
  return request({
    url: '/api/content/pageByDict',
    method: 'POST',
    data,
  })
}
// 获取标签关联栏目分页接口
export function pageByTag(data) {
  return request({
    url: '/api/content/pageByTag',
    method: 'POST',
    data,
  })
}
// 获取文章标签数据
export function contentTags(data) {
  return request({
    url: ' /api/content/tags',
    method: 'POST',
    data,
  })
}
// 获取标签关联栏目分页接口
export function pageByTagFromContent(data) {
  return request({
    url: '/api/content/pageByTagFromContent',
    method: 'POST',
    data,
  })
}
// 获取标签关联栏目分页接口
export function listByParentCodeAndTag(data) {
  return request({
    url: '/api/content/listByParentCodeAndTag',
    method: 'POST',
    data,
  })
}

// 积分规则记录接收
export const integralRuleRecord = (data) => {
  return request({
    url: '/inte-engine/receive_engine/action',
    method: 'post',
    data
  })
}

// 浏览量更新
export const updateViewCount = (params) => {
  return request({
    url: '/inte-engine/receive_engine/action',
    method: 'get',
    params
  })
}

// 获取浏览量
export const getViewCount = (params) => {
  return request({
    url: '/api/getBrowseCount',
    method: 'get',
    params
  })
}

// 全局搜索
export function searchContent(params) {
  return request({
    url: '/api/fullSearch',
    method: 'GET',
      params,
  })
}

// 获取交流展览
export function discussionExhibition() {
  return request({
    url: '/api/exhibition/pageExhibitioninterflow',
    method: 'get',
  })
}

// 5星评价
export function starEvaluate(data) {
  return request({
    url: '/app/web-star/create',
    method: 'post',
    data
  })
}

//失物招领
export function getLostFound(data) {
  return request({
    url:'/api/content/lostandfound',
    method:'post',
    data
  })
}
