<template>
  <div class="page" v-if="showMenu">
    <aside class="aside">
      <nav class="common-aside aside-nav">
        <ul>
          <li
            class="nav-item"
            v-for="(item, index) in navList[activeIndex1].children"
            :key="index"
            :class="[
              index === activeIndex2 ? 'active' : '',
              item.showSecondMenu ? 'actived' : '',
            ]"
          >
            <a
              href="#"
              :class="[index === activeIndex2 ? 'active' : '']"
              @click.prevent="changeTab(item, index)"
              >{{ item.categoryName }}</a
            >
            <i
              class="iconfont iconxiangyou"
              @click="changeSecndMenu(index)"
              v-if="item.children && item.children.length > 0"
            ></i>
            <ul
              class="third"
              v-if="
                item.showSecondMenu && item.children && item.children.length > 0
              "
            >
              <li class="nav-item" v-for="(v, i) in item.children" :key="i">
                <a
                  href="#"
                  :class="[i === activeIndex3 ? 'active' : '']"
                  @click.prevent="changeTab(v, index)"
                  >{{ v.categoryName }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <div class="common-aside aside-exh">
        <webStar></webStar>
      </div>
    </aside>
  </div>
</template>

<script>
import { openLink } from "@/utils/common";
import tree from "@/utils/tree";
import webStar from '@/components/webStar'
export default {
  components: {
    webStar
  },
  data() {
    return {
      navList: [],
      showMenu: false,
      showSecondMenu: true,
      activeIndex1: -1,
      activeIndex2: -1,
      activeIndex3: -1,
    };
  },
  watch: {
    "$store.state.app.menuList": {
      handler(val) {
        if (val && val.length > 0) {
          this.navList = tree(this.$store.state.app.menuList).filter(
            (v) => v.visible
          );
          this.setActiveRouter();
        }
      },
      deep: true,
    },
    $route: {
      handler() {
        this.setActiveRouter();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (
      this.$store.state.app.menuList &&
      this.$store.state.app.menuList.length > 0
    ) {
      this.navList = tree(this.$store.state.app.menuList).filter(
        (v) => v.visible
      );
      this.setActiveRouter();
    }
  },
  methods: {
    setActiveRouter() {
      this.showMenu = false;
      this.showSecondMenu = true;
      this.activeIndex1 = -1;
      this.activeIndex2 = -1;
      this.activeIndex3 = -1;
      this.navList.forEach((firstMenu, index) => {
        firstMenu.children &&
          firstMenu.children.forEach((secondMenu, ind) => {
            if (
              secondMenu.categoryCode == this.$route.name ||
              (this.$route.meta.name &&
                secondMenu.categoryCode == this.$route.meta.name) ||
              (this.$route.params.name &&
                secondMenu.categoryCode == this.$route.params.name)
            ) {
              this.activeIndex1 = index;
              this.activeIndex2 = ind;
              secondMenu.showSecondMenu = true;
              this.showMenu = true;
            } else {
              secondMenu.showSecondMenu = false;
            }
            let i = secondMenu?.children?.findIndex(
              (value) =>
                value.categoryCode == this.$route.name ||
                (this.$route.meta.name &&
                  value.categoryCode == this.$route.meta.name) ||
                (this.$route.params.name &&
                  value.categoryCode == this.$route.params.name)
            );
            if ((i && i !== -1) || i === 0) {
              this.showMenu = true;
              secondMenu.showSecondMenu = true;
              this.activeIndex1 = index;
              this.activeIndex2 = ind;
              this.activeIndex3 = i;
            }
          });
      });
    },
    changeSecndMenu(index) {
      this.navList[this.activeIndex1].children[index].showSecondMenu =
        !this.navList[this.activeIndex1].children[index].showSecondMenu;
      this.$forceUpdate();
    },
    changeTab(item) {
      openLink(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 240px;
  margin-left: 40px;

  .aside {
    width: 240px;

    .common-aside {
      margin-bottom: 20px;
      background-color: #f7f7f7;
    }

    .active {
      color: #6b221e !important;
      background-color: #eee;
    }

    .actived {
      .iconfont {
        color: #6f1b1b !important;
        transform: rotate(90deg);
        transition: transform 0.2s ease;
      }
    }

    .aside-nav > ul .nav-item {
      position: relative;

      a {
        display: block;
        padding-left: 20px;
        height: 50px;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        font-size: 16px;
        color: #444;
        line-height: 50px;

        &:hover {
          color: #851b1d !important;
        }
      }

      .iconfont {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        font-size: 12px;
        text-align: center;
        color: #403939;
        line-height: 50px;
        cursor: pointer;
        transition: transform 0.2s ease;
      }

      /* .iconxiangxia:before {
        content: "\e608";
      } */

      .third {
        overflow: hidden;
        max-height: 400px !important;
        background-color: #fcfcfc;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

        .nav-item:first-child {
          padding-top: 15px;
        }

        .nav-item a {
          padding-left: 33px;
          height: 50px;
          font-size: 16px;
          color: #8e8e8e;
          background: none;
          line-height: 50px;
          transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        }

        .nav-item {
          border-bottom: 0;
        }
      }
    }

    .aside-exh {
      
    }
  }
}
</style>