import axios from 'axios'
// import storage from '@/store'
import config from '@/config'
// import router from '@/router'

const { baseUrl, message: messageConfig } = config


function messageToast(msg) {
  if (messageConfig.showErrorMessage)
    this.$message.error(msg)
}

// axios 实例化配置
const instance = axios.create({
  baseURL: '',
  timeout: 30 * 1000,
})

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    config.headers['app-token'] = localStorage.getItem('APP_TOKEN')
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    const isBlob = response.config?.responseType === 'blob'
    return isBlob ? response : response.data
  },
  (error) => {
    const err = errorMessageHandler(error)
    return Promise.reject(err)
  },
)

// 异常 Message 处理器
function errorMessageHandler(error) {
  const status = error.response && error.response.status

  switch (status) {
    case 400:
      error.message = '请求错误'
      break
    case 401:
      error.message = '未授权，请登录'
      break
    case 403:
      error.message = '拒绝访问'
      break
    case 404:
      error.message = `请求地址出错: ${error.response.config.url}`
      break
    case 408:
      error.message = '请求超时'
      break
    case 500:
      error.message = '服务器内部错误'
      break
    case 501:
      error.message = '服务未实现'
      break
    case 502:
      error.message = '网关错误'
      break
    case 503:
      error.message = '服务不可用'
      break
    case 504:
      error.message = '网关超时'
      break
    case 505:
      error.message = 'HTTP版本不受支持'
      break
    default:
      break
  }

  // 其他错误
  if (error.message) {
    if (error.message === 'Network Error')
      error.message = '网络连接错误，请检查网络环境！'

    if (error.message.includes('timeout'))
      error.message = '网络连接超时，请重试！'
  }

  return error
}

// 项目逻辑二次封装
function request(options) {
  return new Promise((resolve, reject) => {
    instance(options)
      .then((res) => {
        const { code, msg } = res

        // 处理业务 code 异常情况
        if (code !== undefined) {
          switch (code) {
            case 0:
            case 200:
              break

            case 401:
              messageToast(msg)
              // gotoLogin()
              break

            default:
              messageToast(msg)
              break
          }
        }

        resolve(res)
      })
      .catch((err) => {
        const status = err.response && err.response.status

        if (status === 401)
          // gotoLogin()

        messageToast(err.message)
        reject(err)
      })
  })
}

// 前往登录页
// function gotoLogin() {
//   storage.remove('token')
//   if (process.env.NODE_ENV !== 'development')
//     window.location.href = `${config.sysUrl}loading?ddtab=true`
//   else
//     router.push({ name: 'login' })
// }

export default request
