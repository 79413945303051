<template>
  <div class="page-container">
    <div class="swiper-container">
      <el-carousel :interval="5000" arrow="hover" height='585px'>
        <el-carousel-item v-for="(item,index) in carouselList" :key="index">
          <img :src="item.img" alt="" style="width:100% ;height:100%"  @click="handleDetail">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content-container-outside">
      <div class="content-container">
        <div class="area-list">
          <div class="area-item" v-for="(item,index) in areaList" :key="index" @click.stop="showArea(index)">
            <img class="item-img" :src="item.img">
            <div class="item-name">{{ item.name }}</div>
            <div class="marker">
              <span class="el-icon-view view-icon"></span>
            </div>
          </div>
        </div>
        <div class="theme-list">
          <div class="theme-header">
            <img class="crane" src="@/assets/wenchuang/crane.png"></img>
            <div class="title">
              <div class="cn">文创主题系列</div>
              <div class="en">Creative Theme Series</div>
            </div>
          </div>
          <div :class="`theme-item ${index % 2 === 0 ? 'one' : 'two'}`" v-for="(theme,index) in themeList" :key="index">
            <img class="theme-bg" :src="theme.bg">
            <img
              v-appear-animation="{ appearClass:`${index % 2 === 0 ? 'animate__fadeInRight' : 'animate__fadeInLeft'}`}"
              :class="`cloud animate__animated ${index === 0 ? 'isFirst' : ''}`"
              :src="require(`@/assets/wenchuang/cloud-${index % 4 + 1}.png`)">
            </img>
            <div
              v-appear-animation="{ appearClass:`${index % 2 === 0 ? 'animate__fadeInRight' : 'animate__fadeInLeft'}`}"
              class="theme-name animate__animated">
              <div class="top">{{ theme.name.slice(0,2) }}</div>
              <div class="bottom">{{ theme.name.slice(2,4) }}</div>
            </div>
            <div class="content-list">
              <div class="content-item">
                <div class="pic animate__animated" :key="`pics-${index}-${theme.activeChildInd}`" :style="theme.children[theme.activeChildInd].picStyle"
                  v-appear-animation="{ appearClass:`${index % 2 === 0 ? 'animate__fadeInLeft' : 'animate__fadeInRight'}`}">
                  <img :src="theme.children[theme.activeChildInd].pic">
                </div>
                <div class="infos animate__animated"
                  :key="`infos-${index}-${theme.activeChildInd}`"
                  v-appear-animation="{ appearClass:`${index % 2 === 0 ? 'animate__fadeInRight' : 'animate__fadeInLeft'}`}">
                  <div class="name">
                    <img class="title-decoration left" src="@/assets/wenchuang/theme/title-decoration.png">
                    <span>{{ theme.children[theme.activeChildInd].name }}</span>
                    <img class="title-decoration right" src="@/assets/wenchuang/theme/title-decoration.png">
                  </div>
                  <div class="desc">{{ theme.children[theme.activeChildInd].desc }}</div>
                  <div class="slide-dot-container" v-if="theme.children && theme.children.length > 1">
                    <div class="slide-dot"
                      v-for="(item,ind) in theme.children" :key="ind"
                      @click="theme.activeChildInd = ind">
                      <img v-if="theme.activeChildInd == ind" src="@/assets/wenchuang/theme/dot-active.png">
                      <img v-else src="@/assets/wenchuang/theme/dot.png">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="auth-image">
          <img src="@/assets/wenchuang/authImg.png" alt="馆藏IP授权">
        </div>
        <div class="func-list">
          <div class="func-item" v-for="(item,index) in funcList" :key="index">
            <img class="item-bg" :src="item.bgImg">
            <div :class="`name ${index === 0 ? 'one' : 'two'}`">
              <div class="cn">{{ item.cn + ' 》' }}</div>
              <div class="en">{{ item.en }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-image-viewer v-if="showAreaVisible" :on-close="closeImgViewer" :url-list="[areaList[showIndex].floorImg]" />

  </div>
</template>

<script>
export default {
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },

  data() {
    return {
      carouselList: [
        {
          img: require("@/assets/wenchuang/carousel/1.png")
        },
        {
          img: require("@/assets/wenchuang/carousel/1.png")
        },
        {
          img: require("@/assets/wenchuang/carousel/1.png")
        }
      ],
      areaList: [
        {
          img: require("@/assets/wenchuang/zjarea.png"),
          floorImg: require("@/assets/wenchuang/floor-zj.png"),
          name: '之江馆区文创空间'
        },
        {
          img: require("@/assets/wenchuang/gsarea.png"),
          floorImg: require("@/assets/wenchuang/floor-gs.png"),
          name: '孤山馆区文创空间'
        }
      ],

      showIndex: null,
      showAreaVisible: false,


      themeList: [
        {
          name: '镇馆之宝',
          bg: require('@/assets/wenchuang/theme/theme-bg-1.png'),
          activeChildInd:0,
          children: [
            {
              name: '富春再望-四季流沙杯垫',
              pic: require('@/assets/wenchuang/theme/1-1.png'),
              picStyle: { width: '357px', height: '375px' },
              desc: '本系列产品以“元黄公望富春山居图卷”为设计灵感，取画卷中的山水图案为核心设计元素，通过图像复原以及简约的线条重绘，并搭配艾绿、石青、靛青、桃红等清雅大气的中式传统配色，令人观之如身临其境，对古代江南生活充满遐想。相继开发了快客杯、玻璃杯、露营灯、帆布包、电脑包、流沙杯垫、金属书签、折扇、冰箱贴等多款产品，有利于促进富春山居图文化故事的广泛传播。'
            },
            {
              name: '无极鱼杯',
              pic: require('@/assets/wenchuang/theme/3-1.png'),
              picStyle: { width: '398px', height: '451px' },
              desc: '本系列产品以“南宋龙泉窑青瓷莲花纹鸟盏”为设计灵感，取青瓷莲花纹鸟盏的色彩、造型为核心元素进行创作，通过解构、重构，以现代艺术还原其精美的浮雕工艺细节和釉色美学，开发了茶具礼盒、杯垫、晴雨伞等文创产品，有利于促进莲花纹鸟盏和龙泉窑青瓷文化的广泛传播。'
            }
          ]
        },
        {
          name: '笔墨丹青',
          bg: require('@/assets/wenchuang/theme/theme-bg-2.png'),
          activeChildInd:0,
          children: [
            {
              name: '西湖功夫',
              pic: require('@/assets/wenchuang/theme/2-1.png'),
              picStyle: { width: '488px', height: '529px' },
              desc: '本系列产品以“清董诰《西湖十景画册》”为设计灵感，取画册中的西湖十景图为核心设计元素，还原清新淡雅、烟波浩渺的清代西湖景色。相继开发了茶叶礼盒、团扇、冰箱贴、镜子、明信片等多款产品，有利于促进古代西湖十景文化的广泛传播。'
            }
          ]
        },
        {
          name: '千峰翠色',
          bg: require('@/assets/wenchuang/theme/theme-bg-3.png'),
          activeChildInd:0,
          children: [
            {
              name: '无极鱼杯',
              pic: require('@/assets/wenchuang/theme/3-1.png'),
              picStyle: { width: '398px', height: '451px' },
              desc: '本系列产品以“南宋龙泉窑青瓷莲花纹鸟盏”为设计灵感，取青瓷莲花纹鸟盏的色彩、造型为核心元素进行创作，通过解构、重构，以现代艺术还原其精美的浮雕工艺细节和釉色美学，开发了茶具礼盒、杯垫、晴雨伞等文创产品，有利于促进莲花纹鸟盏和龙泉窑青瓷文化的广泛传播。'
            }
          ]
        },
        {
          name: '专题系列',
          bg: require('@/assets/wenchuang/theme/theme-bg-4.png'),
          activeChildInd:0,
          children: [
            {
              name: '雷峰塔系列',
              pic: require('@/assets/wenchuang/theme/4-1.png'),
              picStyle: { width: '529px', height: '421px' },
              desc: '器形呈扁矮的方柱体，内圆外方，上下端为圆面的射，中有对钻圆孔，留有台痕。下端有取料时形成的凹缺。琮体四面由中间的直槽一分为二，又由横槽分为两节，每节再分上下两个组成部分。四面直槽内上下各琢刻一神人兽面纹图像，共八个，用浅浮雕和细线刻两种技法雕琢而成。在分为两节作角尺形的长方形凸面上，以转角为中轴线向两侧展开，每两节琢刻一组简化的象征性的神人兽面纹图案，四角相同，左右对称。'
            }
          ]
        }
      ],
      funcList: [
        {
          bgImg: require('@/assets/wenchuang/func/bg1.png'),
          cn: '文创工作概览',
          en: 'Cultural and Creative Work Overview',
          url: ''
        },
        {
          bgImg: require('@/assets/wenchuang/func/bg2.png'),
          cn: '联系我们',
          en: 'Contact Us',
          url: ''
        },
      ],
    }
  },
  mounted() {

  },
  methods: {
    showArea(index) {
      this.showIndex = index;
      this.showAreaVisible = true;
    },
    closeImgViewer() {
      this.showIndex = null;
      this.showAreaVisible = false;
    },
    handleDetail() {
      this.$router.push({
        path:'/Culture_product_detail',
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  background: url('~@/assets/wenchuang/bg.png') no-repeat;
  background-size: cover;
}

.swiper-container {
  width: 100%;
  height: 585px;

  ::v-deep .el-carousel__button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  ::v-deep .el-carousel__indicator--horizontal {
    padding: 21px 8px;
  }
}

.content-container-outside {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x:hidden;
}

.content-container {
  width: 1200px;
  padding: 40px 0;

  .area-list {
    display: flex;
    gap: 20px;

    .area-item {
      cursor: pointer;
      position: relative;

      .marker {
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);
        z-index: 9;

        .view-icon {
          color: rgba(255, 255, 255, 1);
          font-size: 48px;
        }
      }

      &:hover {
        .marker {
          visibility: visible;
        }
      }

      .item-img {
        width: 580px;
        height: 304px;
      }

      .item-name {
        padding: 9px 16px;
        background-color: #F7EFDC;
        font-family: STKaiti;
        font-size: 32px;
        color: #834A2B;
        line-height: 48px;
        -webkit-text-stroke: 1px #C9A574;
        text-align: left;
        font-style: normal;
      }
    }
  }

  .theme-list {
    margin-top: 40px;
    background: url('~@/assets/wenchuang/contentBg.png') no-repeat;
    background-size: 100% 100%;

    .theme-header {
      padding-top: 48px;
      margin-bottom: 6px;
      position: relative;

      .crane {
        position: absolute;
        width: 71px;
        height: 85px;
        top: 36px;
        left: 371px
      }

      .title {
        position: relative;
        width: 338px;
        height: 80px;
        margin: 0 auto;
        background: url('~@/assets/wenchuang/theme-header-bg.png') no-repeat;
        background-size: 100% 100%;
        text-align: center;

        .cn {
          font-family: STKaiti;
          font-size: 40px;
          color: #834A2B;
          line-height: 48px;
          letter-spacing: 1px;
          -webkit-text-stroke: 1px #814627;
        }

        .en {
          font-family: STSongti-SC, STSongti-SC;
          font-weight: 400;
          font-size: 20px;
          color: #834A2B;
          line-height: 28px;
        }
      }
    }

    .theme-item {
      position: relative;
      padding: 0 30px;
      height: 600px;
      margin-top: 30px;

      .theme-bg {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
      }

      .cloud {
        position: absolute;
        width: 245px;
        height: 116px;
        right: -78px;
        top: -60px;
        z-index: 1;
        &.isFirst {
          top: -160px !important;
        }

        img {
          width: 100%;
          height: 100%;
        }

      }

      .theme-name {
        position: absolute;
        top: 0;
        font-family: YunFengFeiYunTi2;
        font-size: 140px;
        line-height: 130px;
        color: #FFFDFA;
      }

      .content-list {
        margin-top: 5px;

        .content-item {
          position: relative;
          display: flex;
          padding-top: 110px;
          gap: 18px;

          .pic {
            flex-shrink: 0;
            width: 357px;
            height: 375px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .infos {
            padding-top: 40px;

            .name {
              font-family: STKaiti;
              font-size: 36px;
              color: #C9A574;
              line-height: 48px;
              -webkit-text-stroke: 1px #C9A574;
              display: flex;
              align-items: center;
              gap: 13px;

              .title-decoration {
                width: 32px;
                height: 45px;
                flex-shrink: 0;

                img {
                  width: 100%;
                  height: 100%;
                }

                &.right {
                  transform: rotateY(180deg);
                }
              }
            }

            .desc {
              max-width: 530px;
              margin-top: 26px;
              font-family: STKaiti;
              font-size: 20px;
              color: #77582D;
              line-height: 20px;
            }

            .slide-dot-container {
              margin-top: 60px;
              display: flex;
              align-items: center;
              gap: 28px;
              .slide-dot {
                width: 35px;
                height: 35px;
                cursor: pointer;
                img {
                  width:100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      &.one {
        .cloud {
          right: -78px;
        }

        .theme-name {
          right: 0;

          .top {
            text-align: left;
            margin-right: 95px;
          }

          .bottom {
            text-align: right;
          }
        }

        .content-item {
          flex-direction: row;
        }

      }

      &.two {
        .cloud {
          left: -78px;
        }

        .theme-name {
          left: 295px;

          .top {
            text-align: right;
            margin-left: 55px;
          }

          .bottom {
            text-align: left;
          }
        }

        .content-item {
          flex-direction: row-reverse;
          justify-content: space-between;
          padding-top: 70px;
          .infos {
            padding-top: 100px;
            padding-left: 30px;
          }
        }
      }
    }
  }

  .auth-image {
    margin-top: 40px;
    width: 100%;
    height: 230px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .func-list {
    margin-top: 40px;
    display: flex;
    gap: 40px;

    .func-item {
      flex: 1;
      height: 120px;
      padding: 37px 26px 37px 26px;
      position: relative;
      cursor: pointer;

      .item-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .name {
        position: relative;

        &.one {
          color: #FFFFFF;

          .cn {
            -webkit-text-stroke: 1px #FFFFFF;
          }

        }

        &.two {
          color: #9B7745;

          .cn {
            -webkit-text-stroke: 1px #9B7745;
          }

        }

        .cn {
          font-family: STKaiti;
          font-size: 32px;
          line-height: 38px;
        }

        .en {
          font-family: STSongti-SC, STSongti-SC;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          text-indent: 3px;
        }
      }
    }
  }
}
</style>