import store from "@/store";
import router from "@/router";
import { contentList,contentDetail, contentNew } from "@/api/system";
/* 获取标题*/
export function getTitle(code) {
  const index = store.state.app.categoryList.findIndex(
    (item) => item.categoryCode == code
  );
  if ((index && index !== -1) || index === 0) {
    return store.state.app.categoryList[index].categoryName;
  }else{
    return ''
  }
}
/* 获取分页数据*/
export function getPageData(params) {
  return new Promise((resolve, reject) => {
    contentList(params)
      .then((res) => {
        if (res.success) {
          const resData=res?.data.filter(v=>v!=='0 rows affected')??[]
          const data={
            ...resData?.[0]?.[0]??{},
            records:resData?.[1]??[],
            success:true
          }
          resolve(data);
        } else {
          reject(res.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/* 获取分页数据*/
export function getPageNew(params) {
  return new Promise((resolve, reject) => {
    contentNew(params)
      .then((res) => {
        if (res.success) {
          const resData=res?.data.filter(v=>v!=='0 rows affected')??[]
          const data={
            ...resData?.[0]?.[0]??{},
            records:resData?.[1]??[],
            success:true
          }
          resolve(data);
        } else {
          reject(res.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/* 获取详情数据*/
export function getPageDeatil(params) {
  return new Promise((resolve, reject) => {
    contentDetail(params)
      .then((res) => {
        if (res.success) {
          const resData=res?.data.filter(v=>v!=='0 rows affected')??[]
          const data={
            data:resData?.[0]??[],
            success:true
          }
          resolve(data);
        } else {
          reject(res.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function checkRouteNameExists(name) {
  const route = router.options.routes.find(r => r.name === name);
  return !!route;
}
/* 跳转*/
export function openLink(item) {
  if (window.stop) {
    window.stop();
  } else {
    document.execCommand("stop");
  }
  if(item.type===3){
    if(item.linkUrl.includes('http')){
      if(item.linkUrl.includes(window.location.origin)){
        window.open(item.linkUrl,'_self')
      }else{
        window.open(item.linkUrl,'_blank')
      }
      
    }else{
      router.push({ path: item.linkUrl })
    }
  }else{
    if (checkRouteNameExists(item.categoryCode)) {
      router.push({ name: item.categoryCode });
    }
  }
}

/**
 * 富文本去标签
 * @param {*} cont 富文本
 * @param {*} len  字数截取值
 * @param {*} n 
 * @returns 
 */
export function richTextRemoveTag(cont, len, n) {
  if (cont == null || cont == "") return '  '
  let r = cont.replace(/&rdquo;/g, "\u201d").replace(/&ldquo;/g, "\u201c").replace(/&quot;/g, '"').replace(/<[^>]+>|&[^>]+;/g, "").trim(), a = r.replace(/<[^>]+>/g, "").trim();
    if ("" === a)
        return "";
    if (n) {
      let o = cont.replace(/<[^>]+>|&[^>]+;/g, "").trim();
      if (o.length < len)
        return o;
      let i = o.substr(0, Number(len))
      , c = i.lastIndexOf(" ");
        return "".concat(i.substr(0, c), "...")
    }
    return a.length < len ? "".concat(a) : "".concat(len > 0 ? a.substr(0, Number(len)) : a, "...")
}

/* 获取信息 */
export function parseUserAgent(userAgent) {
  var ua = userAgent.toLowerCase();
  var browser = {
    browser: '',
    version: '',
    os: '',
    os_version: ''
  };
  // 测试浏览器
  if (ua.indexOf('msie') !== -1) {
    browser.browser = 'IE';
    browser.version = ua.match(/msie ([0-9]+)/)[1];
  } else if (ua.indexOf('trident') !== -1) {
    browser.browser = 'IE';
    browser.version = ua.match(/rv:([0-9]+)/)[1];
  } else if (ua.indexOf('firefox') !== -1) {
    browser.browser = 'Firefox';
    browser.version = ua.match(/firefox\/([0-9]+)/)[1];
  } else if (ua.indexOf('chrome') !== -1) {
    browser.browser = 'Chrome';
    browser.version = ua.match(/chrome\/([0-9]+)/)[1];
  } else if (ua.indexOf('safari') !== -1) {
    browser.browser = 'Safari';
    browser.version = ua.match(/version\/([0-9]+)/)[1];
  } else if (ua.indexOf('opera') !== -1) {
    browser.browser = 'Opera';
    browser.version = ua.match(/version\/([0-9]+)/)[1];
  } else if (ua.indexOf('webkit') !== -1) {
    browser.browser = 'Unknown Webkit';
    browser.version = ua.match(/webkit\/([0-9]+)/)[1];
  }
  // 测试操作系统
  if (ua.indexOf('windows') !== -1) {
    browser.os = 'Windows';
  } else if (ua.indexOf('mac') !== -1) {
    browser.os = 'Mac';
  } else if (ua.indexOf('linux') !== -1) {
    browser.os = 'Linux';
  } else if (ua.indexOf('x11') !== -1) {
    browser.os = 'Unix';
  } else {
    browser.os = 'Other';
  }

  if (ua.match(/windows\snt\s(\d+)\.(\d+)/)) {
    browser.os = 'Windows';
    browser.os_version = RegExp.$1 + '.' + RegExp.$2; // 获取 Windows NT 的主版本和次版本
  } else if (ua.match(/macintosh|mac os x/i)) {
    browser.os = 'Mac';
    var osxMatch = ua.match(/os\s(\d+)_(\d+)_?(\d+)?/);
    if (osxMatch) {
      browser.os_version = osxMatch[1] + '.' + osxMatch[2];
      if (osxMatch[3]) {
        browser.os_version += '.' + osxMatch[3];
      }
    } else {
      // 对于没有详细版本的 macOS，可以假设一个大致的版本（比如10.x）
      browser.os_version = '10.x';
    }
  } else if (ua.match(/android\s(\d+)\.(\d+)/)) {
    browser.os = 'Android';
    browser.os_version = RegExp.$1 + '.' + RegExp.$2; // 获取 Android 的主版本和次版本
  } else {
    browser.os = 'Unknown';
    browser.os_version = 'Unknown';
  }

  return browser;
}