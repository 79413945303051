<template>
  <div class="main" :class="[$route.meta.dispalyMargin ? '' : 'main-padding']">
    <div class="content flex-1">
      <router-view />
    </div>
    <leftMenu />
  </div>
</template>
<script>
import leftMenu from "./leftMenu.vue";
export default {
  components: { leftMenu },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.main {
  display: flex;
  justify-content: space-between;
  .content {
    min-width: 920px;
  }
}
.main-padding {
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 60px;
  padding-bottom: 100px;
}
.flex-1 {
  flex: 1 1 0%;
}
</style>