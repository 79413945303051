<template>
  <div class="layout">
    <LayoutHeader v-show="!$route.meta.hideHeader" class="layout-header">
      <MoocHeader @showBg="getBg" />
    </LayoutHeader>
    <!-- <LayoutMain
      class="layout-main"
      :style="{ 'margin-top': $route.meta.hideHeader ? 0 : '70px' }"
    /> -->
    <LayoutMain
      class="layout-main"
      :style="{ 'margin-top': $route.meta.hideHeader ? 0 : '0px' }"
    />
    <LayoutFooter v-show="!$route.meta.hideFooter" >
      <Moocfirstpart/>
      <Moocrelated/>
    </LayoutFooter>
    <LayoutFooter v-show="!$route.meta.hideFooter" class="layout-footer">
      <MoocFooter />
    </LayoutFooter>
    <div class="bg-w" v-show="bgShow" @mouseover="bgShowmou"></div>
  </div>
</template>
<script>
import LayoutHeader from "./header/index.vue";
import LayoutMain from "./main/index.vue";
import LayoutFooter from "./footer/index.vue";
import MoocHeader from "@/components/header/index.vue";
import MoocFooter from "@/components/footer/index.vue";
import Moocfirstpart from "@/components/footer/firstpart.vue";
import Moocrelated from "@/components/footer/related.vue";
export default {
  name: "LayoutsIndex",
  components: {
    LayoutHeader,
    LayoutMain,
    LayoutFooter,
    MoocHeader,
    MoocFooter,
    Moocfirstpart,
    Moocrelated
  },
  data() {
    return {
      meta: "",
      bgShow: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    getBg(v) {
      this.bgShow = v;
    },
    bgShowmou() {
      this.bgShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "~@/assets/styles/variables.scss";
// $header-height: 70px;
.layout {
  display: flex;
  flex-direction: column;
  min-width:1440px;
  min-height: 100vh;
  &-header {
    background: url("../assets/header-bj.jpg") repeat-x;
    line-height: 95px;
  }
  &-main {
    flex: auto;
  }
  &-footer {
    height: 331px;
    padding: 45px 0 20px 0;
    background: #060505;
    // overflow: hidden;
    line-height: 28px;
    font-size: 16px;
  }
}
.bg-w {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
</style>
