import { getTitle, getPageData } from '@/utils/common'
import { merge } from 'lodash'
const pubilcList = {
  data() {
    return {
      getTitle,
      paginationData: {
        pageNum: 1,
        pageSize: 10,
      },
      pageTotal: 0,
      contList: [],
      pageLoading: false,
    }
  },

  methods: {
    initPublicData(paginationData) {
      this.pageLoading=true
      const pagination = merge(this.paginationData, paginationData ?? {})
      let params = {
        categoryCode: this.categoryCode ?? this.$route.name,
        siteId: this.$store.state.app.siteData[0].id,
        ...pagination,
      }
      getPageData(params).then((res) => {
        //console.log('列表数据', res)
        if (res.success) {
          this.pageTotal = res.total
          this.contList = res.records
        }
      })
      this.pageLoading=false
    },

    handleCurrentChange(val) {
      this.paginationData.pageNum = val
      this.initPublicData()
    },
  },
}

export default pubilcList
