<template>
  <div class="firstpart">
    <div class="firstpart-content">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="firstpart-content-item"
      >
        <div class="item-title item-hover">
          <a :href="item.url" @click.prevent="gopush(item)">{{
            item.categoryName
          }}</a>
        </div>
        <div v-if="item.children && item.children.length > 0" class="itm">
          <div v-for="(itm, idx) in item.children" :key="idx" class="itm-title">
            <div class="item-hover">
              <a :href="itm.url" @click.prevent="gopush(itm)">{{
                itm.categoryName
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { openLink } from "@/utils/common";
import tree from "@/utils/tree";
export default {
  data() {
    return {
      list: [],
    };
  },
  watch: {
    "$store.state.app.menuList": {
      handler(val) {
        if (val && val.length > 0) {
          this.list = tree(this.$store.state.app.menuList).filter(
            (v) => v.visible
          );
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (
      this.$store.state.app.menuList &&
      this.$store.state.app.menuList.length > 0
    ) {
      this.list = tree(this.$store.state.app.menuList).filter((v) => v.visible);
    }
  },
  methods: {
    gopush(item) {
      openLink(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.firstpart {
  width: 100%;
  background: url("../../assets/footer-bg.jpg") no-repeat;
  background-size: 100% 100%;
  padding: 40px 0;

  &-content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-item {
      display: flex;
      justify-content: left;
      width: 46%;
      height: 52px;
      flex-wrap: nowrap;
      align-items: center;

      .item-title {
        padding-right: 22px;
        width: 100px;
        font-size: 18px;
        font-weight: 700;
        text-align: justify;
        text-justify: distribute-all-lines;
        color: #0f0f0f;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        text-align-last: justify;

        &::after {
          content: ">";
          position: absolute;
          padding-left: 7px;
          font-weight: 400;
        }
      }

      .item-hover {
        transform: translateY(-2px);
      }

      .item-hover:hover {
        transform: translateY(-2px);
        color: #851b1d;
        /* 文字颜色也改为白色 */
      }

      .itm {
        display: flex;
        font-size: 16px;
        flex-wrap: wrap;
        line-height: 26px;

        &-title {
          padding: 0 14px 0 8px;
        }
      }
    }
  }
}
</style>