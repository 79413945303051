<template>
  <div id="app">
    <!-- <router-view></router-view> -->
    <Layout></Layout>
  </div>
</template>
<script>
import Layout from "@/layouts/index.vue";
import '@/utils/assist-entry';
export default {
  name: "App",
  components: {
    Layout,
  },
}
</script>
<style>
@import "~@/assets/fonts/font.css";
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#app {
  height: 100vh;
  overflow: auto;
}
</style>
