<template>
  <div class="footer">
    <div class="footer-box">
      <div class="footer-top">
        <div class="footer-top-right">
          <div
            v-for="(item, index) in linkList"
            :key="index"
            class="navlist-cont"
          >
            <h3 v-if="index != 1">
              <span :class="{ sanjiao: index == 1 }">{{ item.titlename }}</span>
            </h3>
            <h3 v-if="index == 1">
              <a :href="item.url"
              @click.prevent="gopush(item)"
                ><span :class="{ sanjiao: index == 1 }" class="hoverColor">{{
                  item.titlename
                }}</span></a
              >
            </h3>
            <template v-if="index == 0">
              <div class="item-list">
                <div
                  v-for="(items, index) in item.titlelist"
                  :key="index"
                  class="item-list-name"
                  :class="items.str"
                  @click="goPageD(items)"
                >
                  <img
                    :src="items.img"
                    alt=""
                    style="width: 28px; height: 28px"
                  />
                  <span class="iocn" style="margin-left: 8px">{{
                    items.name
                  }}</span>
                  <div class="imgbox">
                    <img :src="items.qr" alt="">
                  </div>
                </div>
              </div>
            </template>
            <template v-if="index == 1">
              <div
                v-for="(items, index) in item.titlelist"
                :key="index"
                class="navlist-sub"
                :class="items.str"
                @click.prevent="goPageD(items)"
              >
                <a :href="items.url"
                  ><span class="hoverColor">{{ items.name }}</span></a
                >
              </div>
            </template>
            <template v-if="index == 2">
              <div
                v-for="(items, index) in item.titlelist"
                :key="index"
                class="navlist-sub"
                :class="items.str"
                @click="goPageD(items)"
              >
                <span>{{ items.name }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="thirdpart">
        <p class="thirdpart-p">以人为本 以心传承 以文化人 以物育人</p>
        <div class="thirdpart-item">
          <span>
            <a
              style="display: flex"
              href="http://bszs.conac.cn/sitename?method=show&id=25E6C68957AA0E69E053012819AC2310"
            >
              <img src="../../assets/sydw.png" alt="" style="width: 28px" />
            </a>
          </span>
          <span class="thirdpart-span">©2024浙江省博物馆</span>
          <a href="https://beian.miit.gov.cn/"
            ><span class="thirdpart-span">浙ICP备05047720号-3</span></a
          >
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602007667"
          >
            <div class="secpart-bg"></div>
            <span class="thirdpart-span">浙公网安备：33010602007667号</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {
      linkList: [
        {
          titlename: "关注我们",
          titlelist: [
            {
              name: "留言簿",
              img: require("../../assets/icons/9.png"),
              qr: require("../../assets/icons/qr1.jpg"),
            },
            {
              name: "官方微博",
              img: require("../../assets/icons/3.png"),
              qr: require("../../assets/icons/qr2.png"),
            },
            {
              name: "服务号",
              img: require("../../assets/icons/8.png"),
              qr: require("../../assets/icons/qr3.jpg"),
            },
            {
              name: "订阅号",
              img: require("../../assets/icons/1.png"),
              qr: require("../../assets/icons/qr4.jpg"),
            },
            {
              name: "云上浙博",
              img: require("../../assets/icons/8.png"),
              qr: require("../../assets/icons/qr5.png"),
            },
            {
              name: "志愿者",
              img: require("../../assets/icons/8.png"),
              qr: require("../../assets/icons/qr6.jpg"),
            },
            {
              name: "浙江文博集展号",
              img: require("../../assets/icons/8.png"),
              qr: require("../../assets/icons/qr7.jpg"),
              str: 'overlength'
            },
            // {
            //   name: "抖音",
            //   img: require("../../assets/icons/4.png"),
            // },
            // {
            //   name: "bilibili",
            //   img: require("../../assets/icons/5.png"),
            // },
            // {
            //   name: "语音导览",
            //   img: require("../../assets/icons/6.png"),
            // },
            // {
            //   name: "喜马拉雅",
            //   img: require("../../assets/icons/7.png"),
            // },
          ],
          colunmNumber: "p-01",
        },
        {
          titlename: "友情链接",
          name:'FriendLink',
          url: "https://www.zhejiangmuseum.com/FriendLink",
          titlelist: [
            {
              name: "浙江省文化广电和旅游厅",
              url: "http://ct.zj.gov.cn/",
            },
            {
              name: "浙江文物网",
              url: "http://wwj.zj.gov.cn/",
            },
            {
              name: "中国国家博物馆",
              url: "http://www.chnmuseum.cn/",
            },
          ],
          colunmNumber: "ZS",
        },
        {
          titlename: "联系我们",
          titlelist: [
            {
              name: "电话：0571-85391628（之江馆区）",
            },
            {
              name: "0571-86013085/87960505（孤山馆区）",
              str: "indent",
            },
            {
              name: "信箱：zjmuseum@zjmuseum.cn",
            },
            {
              name: "地址：浙江省 杭州市 孤山路25号",
            },
          ],
        },
      ],
      sysInfo: {},
    };
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    gopush(item){
      this.$router.push({
        name:item.name
      })
    },
    goPageD(items){
      if(items.url){
        window.open(items.url)
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.footer {
  color: #fff;
  // display: flex;
  width: 100%;
  height: 100%;
  &-box {
    width: 1200px;
    margin: 0 auto;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .footer-top-left {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: center;
      .footer-top-left-phone {
        display: flex;
        // height: 40px;
        align-items: center;
        justify-content: center;
        .phone-text {
          font-size: 18px;
          font-weight: 500;
          color: #f7f7f7;
          margin-left: 14px;
        }
        .phone-num {
          font-size: 30px;
          font-family: Impact;
          color: #f7f7f7;
          margin-left: 24px;
        }
      }
      .footer-top-left-chart {
        display: flex;
        // width: 100%;
        flex: 0 0 50%;
        align-items: center;
        justify-content: center;
        text-align: center;
        img {
          width: 90px;
          height: 90px;
          overflow: hidden;
        }
        .chart-con {
          margin: 0 20px;
          p {
            font-size: 14px;
            font-weight: 400;
            color: #f7f7f7;
            margin-top: 28px;
            margin-bottom: 20px;
          }
        }
      }
    }
    .footer-top-right {
      display: flex;
      width: 100%;
      justify-content: space-between;
      // border-left: 1px solid #2e3234ff;
      .item-list {
        // margin-top: 28px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        width: 390px;
        &-name {
          margin-bottom: 20px;
          position: relative;
          display: flex;
          flex: 0 0 25%;
          cursor: pointer;
          font-size: 12px;
          align-items: center;
          color: #878687;
          .imgbox{
            position: absolute;
            top: -145px;
            left: 50%;
            display: none;
            margin-left: -65px;
            width: 130px;
            height: 130px;
            z-index:99;
            img{
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
          .imgbox::after{
            position: absolute;
            bottom: -20px;
            left: 50%;
            margin-left: -10px;
            content: "";
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-top: 10px dashed #fff;
          }
        }
        .overlength {
          flex: 0 0 50%;
        }
        .item-list-name:hover .imgbox{
          display:block;    
        }
       
      }
      .navlist-cont {
        display: flex;
        flex-direction: column;
        // margin: 0 40px;
        // flex: 1;
        // align-items: center;
        h3 {
          margin-bottom: 22px;
          font-size: 18px;
          color: #d1d1d1;
          font-weight: 400;
          span {
            padding-bottom: 8px;
            border-bottom: 1px solid #d1d1d1;
            cursor: pointer;
          }
          .sanjiao {
            position: relative;
            &::after {
              position: absolute;
              top: 9px;
              right: -30px;
              content: "";
              width: 0;
              height: 0;
              border-color: #d1d1d1 transparent transparent;
              border-style: dashed solid solid;
              border-width: 8px 5px;
            }
          }
        }
        .navlist-sub {
          font-size: 14px;
          font-weight: 400;
          color: #d1d1d1;
          line-height: 24px;
          height: 24px;
          cursor: pointer;
          // &:hover {
          //   text-decoration: underline;
          // }
        }
      }
    }
  }
  .link-text {
    height: 48px;
    color: #8a8e92;
    p {
      // line-height: 40px;
      font-size: 17px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      // color: #151e26;
      i {
        margin-right: 20px;
      }
      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // color: #5b6167;
      }
    }
  }
}
.indent {
  text-indent: 3em;
}
.hoverColor:hover {
  color: #faf6f5;
}
.thirdpart {
  text-align: center;
  font-size: 14px;
  color: #737174;
  &-p {
    height: 28px;
    font-size: 18px;
    font-family: STKaitiTC-Bold;
    margin-bottom: 13px;
  }
  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .secpart-bg {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAE3klEQVQ4jTXO6W+TdQDA8e/z9Omep3e7YduNDXawsU1giqI4FUgkRFCDJGjUN+ALQowaI0rAI0ZiomCiiTExxuOFCSoRiRfgAYkDBQaek2vs6Oiu7mjX4+nzdG2fPj9fGP+BTz6SLWxExUaUbGzLwumzAB8wsyH26w+br12cerihznu6vbPtmBJq+NGcykw4Fy/HobkRWDicDoQQSP9BFYQlcKhOEBd393195MGR2Gz3THKIeAaWttdhTZeR5ZCxpl072Xz/7v0Ew70lPUuVz/8/JBDWPLKiAbl9+7ZseCUR6GTLvSqeyVmi5hhF4SLZ1MSHX10nJDy8/dKmuNL2eKPQNGSnBIAMIMnaAhh/9uhrj75yqbKKJ/euxz7xM+GDA2TFSszPhrm7f4r39rbiWLSQ19/5cnHm6NN/mpP9D1TMNMX0OAghECL18Ue7N4lIdKfoG/1L9G5fLs56giL50D1i6MlHxOCmbjEcCArjua1i5OJ+Ea1dIx5a2SnGDj1VsPUZComr/42wB9a++olCxwPPEi32MHN0iDsUF/nBBN7v/0CbNnBkTfI/jdBYMlhan+TwcBvzdkErl+WNWrQdeT6f/YBkvMXtC3NTSwYrlyCgW1BRUR0VCg0SuqQjqT7UAhQTefykqKlzkujvY/C7F48Dy5R8cnKHFrCoCULfpSzh59cyfNe36HnIvHo3aTGNmZZwvXCKqjtDKC01XJ6oQgtaKA43ib4eAn/0LFNkkpOoxbqVHSrvH5/mws8aLW+s4+T5Ibyn4ygK5NPzOPfcTmlhDZ++8w2xSQebu6rwu2CsP0Nq8PxdSi4jRHXjUm5d8TuVgyMcOKDxRccEtTNpiqkCSo0LT9LAV6ij6rNeDp7JgLKKmyMGc7FRqttvwOUv7VRsq4iZDLNxvQ8iKVKJVtRzJ1jd5WVuzRIyqsA5Do74FJEJizIStEfp8g+jz8tIegl92ryi1La4Gfvrc9raYO/DJvvfNdgVuBdveRKpx4cqLPSchfMGN4mih14stq0wiTozxC0XmbOXCXe4hxxPPHbfrgplvwONTt8/jOtl+praOdRrk4+20h7NcOw6HBqu50+9xPK1y9nVNMDk8CiBRQ003NhEauxKWIr/9lbO0Kt9uZROU2sWkThDYjZMLFmNa36QBi3BtSGDsVIdQZ+MWilTGbxM7epleCIt5IbOUHEtzcnRxuZsTXQA2xxhfKhMstSEPptiZYcXj1yi98IczqoA69pMWgNZ7OGrhFa0ovi8xM+c5ELPNVq27NsjCavYYY4fPjk6MlFnTs/iCrpJ9J3FmDNwuP2YOZ1AdQgjOcX0aBq/T8NT7SE9GidjKqzafeTN7nWr9yjFfN6XSxZLxYyOEuykLNJoCxpAGic1mQTZzUT/dfSMyaKuNhyyhG1mqKmPIOdtgqWrzbA6JJuJX1429MONgQYJ5q9RTsVQfWG84VaiLfWU9CQOTaW5uxvV7UWu8oJTo6wozMcuc+Xr97dWbFtVAk0bnhHGmCs9c359Iaugp3Nobjd2xcCYmaJ2ST12lQszlUIoZaYHLqFPpfAHI4Ru3nr9jh37tzskeUoSQpC7EiPx96ltBbvwqFGRbyvnB0Lm3CBzsTj++gWUigpmKo875MUViIxUN99yTtUi3y3u6v6qZsniIiWLfwGKjmFjN1ZXIQAAAABJRU5ErkJggg==);
    object-fit: cover;
    vertical-align: middle;
  }
  .thirdpart-span {
    display: inline-block;
    padding: 0 5px;
    font-size: 14px;
    color: #737174;
    vertical-align: middle;
  }
}
</style>
