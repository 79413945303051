import { cloneDeep, merge } from 'lodash'

const DEFAULT_CONFIG = {
  id: 'id',
  children: 'children',
  pid: 'pid',
}

// global config
let treeConfig = DEFAULT_CONFIG

const getMergedConfig = (config = {}) => Object.assign({}, treeConfig, config)

const tree = data => new Tree(data)

function configure(config = {}) {
  treeConfig = getMergedConfig(config)
}

tree.configure = configure

class Tree {
  data

  constructor(data) {
    this.data = cloneDeep(data)
  }

  getData() {
    return this.data
  }

  setData(data) {
    this.data =  cloneDeep(data)
  }

  fromList(config) {
    const { id, children, pid } = getMergedConfig(config)
    const list = cloneDeep(this.data)
    const tree = []
    const nodeMap = new Map()

    for (const node of list) {
      node[children] = node[children] || []
      nodeMap.set(node[id], node)
    }

    for (const node of list) {
      const parent = nodeMap.get(node[pid])
      ;(parent ? parent[children] : tree).push(node)
    }

    return tree
  }

  toList(config) {
    const { children } = getMergedConfig(config)
    const list = [...this.data]

    for (let i = 0; i < list.length; i++) {
      if (!list[i][children])
        continue

      list.splice(i + 1, 0, ...list[i][children])
      list[i][children] = null
    }

    return list
  }

  find(fn, config) {
    const { children } = getMergedConfig(config)
    const list = [...this.data]

    for (const node of list) {
      if (fn(node))
        return node

      node[children] && list.push(...node[children])
    }

    return null
  }

  findPath(fn, config) {
    const { children } = getMergedConfig(config)
    const list = [...this.data]
    const path = []
    const visitedSet = new Set()

    while (list.length) {
      const node = list.at(0)
      if (visitedSet.has(node)) {
        path.pop()
        list.shift()
      }
      else {
        visitedSet.add(node)
        node[children] && list.unshift(...node[children])
        path.push(node)
        if (fn(node))
          return path
      }
    }

    return null
  }

  filter(fn, config) {
    const { children } = getMergedConfig(config)

    const filter = (tree) => {
      return tree.filter(fn).map((node) => {
        node[children] = node[children] && filter(node[children])
        return node
      })
    }

    return filter(this.data)
  }

  forEach(fn, config) {
    const { children } = getMergedConfig(config)
    const list = [...this.data]

    for (let i = 0; i < list.length; i++) {
      const node = list[i]
      fn(node)
      node[children] && list.splice(i + 1, 0, ...node[children])
    }
  }

  map(fn, config) {
    const { children } = getMergedConfig(config)

    const map = (tree) => {
      return tree.map((node) => {
        node[children] = node[children] && map(node[children])
        return fn(node)
      })
    }

    return map(this.data)
  }

  remove(fn, config) {
    const { children } = getMergedConfig(config)
    const list = [this.data]

    while (list.length) {
      const nodeList = list.shift()
      const removeIndexList = nodeList.reduce((idxs, node, index) => {
        fn(node) && idxs.push(index)
        return idxs
      }, [])

      removeIndexList.reverse()
      removeIndexList.forEach(index => nodeList.splice(index, 1))

      const childrenList = nodeList.map(node => node?.[children]).filter(children => children?.length > 0)
      list.push(...childrenList)
    }
  }
}

/**
 * @desc: 根据 parentId 和 id 将列表格式化为树结构
 * @param {*} list
 * @param {*} option
 * @return {*}
 */
export function list2Tree(list, option) {
  const options = merge({ parentId: 'parent_id', id: 'id', topId: '0' }, option)
  const map = new Map()

  list.forEach((item) => {
    const parentId = item[options.parentId]
    if (!map.has(parentId))
      map.set(parentId, [])
    map.get(parentId).push(item)
  })

  list.forEach((item) => {
    const id = item[options.id]
    if (map.has(id))
      item.children = map.get(id)
  })

  return map.get(options.topId)
}

export { tree }
export default tree
